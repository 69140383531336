exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accounting-bookkeeping-registration-js": () => import("./../../../src/pages/accounting-bookkeeping-registration.js" /* webpackChunkName: "component---src-pages-accounting-bookkeeping-registration-js" */),
  "component---src-pages-affiliate-registration-js": () => import("./../../../src/pages/affiliate-registration.js" /* webpackChunkName: "component---src-pages-affiliate-registration-js" */),
  "component---src-pages-amazon-accountant-directory-js": () => import("./../../../src/pages/amazon-accountant-directory.js" /* webpackChunkName: "component---src-pages-amazon-accountant-directory-js" */),
  "component---src-pages-book-js": () => import("./../../../src/pages/book.js" /* webpackChunkName: "component---src-pages-book-js" */),
  "component---src-pages-ecommerce-seller-blog-js": () => import("./../../../src/pages/ecommerce-seller-blog.js" /* webpackChunkName: "component---src-pages-ecommerce-seller-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-pricing-2-js": () => import("./../../../src/pages/pricing2.js" /* webpackChunkName: "component---src-pages-pricing-2-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-quickbooks-disconnect-js": () => import("./../../../src/pages/quickbooks-disconnect.js" /* webpackChunkName: "component---src-pages-quickbooks-disconnect-js" */),
  "component---src-pages-referral-program-taxomate-js": () => import("./../../../src/pages/referral-program-taxomate.js" /* webpackChunkName: "component---src-pages-referral-program-taxomate-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-taxomate-accountants-bookkeepers-js": () => import("./../../../src/pages/taxomate-accountants-bookkeepers.js" /* webpackChunkName: "component---src-pages-taxomate-accountants-bookkeepers-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-templates-accounting-js": () => import("./../../../src/templates/accounting.js" /* webpackChunkName: "component---src-templates-accounting-js" */),
  "component---src-templates-blogs-js": () => import("./../../../src/templates/blogs.js" /* webpackChunkName: "component---src-templates-blogs-js" */),
  "component---src-templates-competitor-js": () => import("./../../../src/templates/competitor.js" /* webpackChunkName: "component---src-templates-competitor-js" */),
  "component---src-templates-ecommerce-js": () => import("./../../../src/templates/ecommerce.js" /* webpackChunkName: "component---src-templates-ecommerce-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

